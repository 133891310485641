import React from 'react';

import Layout, { Content, PageSection } from '../components/layout';
import { graphql } from 'gatsby';

import { Trans, useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Seo from '../components/seo';
import { PageText } from '../components/typography';
import { PrevNextLink, SecondaryButton } from '../components/buttons';

const categories = [
    {
        slug: 'unkategorisiert',
        name: 'Blog',
        url: '/blog/',
    },
    {
        slug: 'article',
        name: 'Artikel',
        url: '/blog/articles/',
    },
    {
        slug: 'news',
        name: 'News',
        url: '/blog/news/',
    },
];

export default function BlogArchive({
    data,
    pageContext: { categorySlug, nextPagePath, previousPagePath },
}) {
    const { t } = useTranslation();
    const posts = data.allMarkdownRemark.edges;
    let currentCategory = categories.filter(c => c.slug === categorySlug);
    if (currentCategory.length === 0) {
        currentCategory.push(categories[0]);
    }
    const currentUrl = currentCategory[0].url;

    const headerProps = {
        location: { pathname: currentUrl },
        headerContent: (
            <Content size="big" className="pt-8 px-8 pb-2" hasPadding={false}>
                <h1 className="text-2xl font-bold">
                    <Trans i18nKey="ressources">Ressourcen</Trans>
                </h1>
            </Content>
        ),
        bgImage: {
            file: 'book',
            opacity: 0.2,
        },
    };

    if (!posts.length) {
        return (
            <Layout {...headerProps}>
                <Seo title={t('allposts', 'Alle Posts')} />
                <PageSection className="my-1" size="big">
                    <PrevNextLink path={currentUrl}>
                        <Trans i18nKey="back">Zurück zum Blog</Trans>
                    </PrevNextLink>
                    <PageText className={'text-center'}>
                        <Trans i18nKey="noposts">
                            Zur Zeit sind keine Einträge verfügbar.
                        </Trans>
                    </PageText>
                </PageSection>
            </Layout>
        );
    }

    let seoTitle = {
        unkategorisiert: t('uncategorized', 'Blog'),
        article: t('article', 'Artikel'),
        news: t('news', 'News'),
    };

    return (
        <Layout {...headerProps}>
            <Seo title={seoTitle[categorySlug]} />
            <div className="bg-light-grey">
                <div className="bg-clean-white">
                    <Content size="big" hasPadding={false} className="px-8">
                        <ul className="flex">
                            {categories.map(c => (
                                <li className="py-2 pr-4" key={c.slug}>
                                    <Link
                                        to={c.url}
                                        className={`link-hover py-2 ${
                                            categorySlug === c.slug
                                                ? 'link font-bold'
                                                : ''
                                        }`}
                                    >
                                        <Trans i18nKey={c.slug}>{c.name}</Trans>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Content>
                </div>

                <ul className="md:grid grid-cols-2 gap-8 max-w-4xl mx-auto pt-8 md:pt-16 px-8 pb-20">
                    {posts.map(post => (
                        <BlogPostPreview post={post} key={post.node.id} />
                    ))}
                </ul>

                <Pagination
                    nextPagePath={nextPagePath}
                    previousPagePath={previousPagePath}
                />
            </div>
        </Layout>
    );
}

function BlogPostPreview({ post }) {
    const { title, date, categories, cover_image } = post.node.frontmatter;
    const { excerpt, fields } = post.node;
    const featuredImage = {
        data: getImage(cover_image),
        alt: 'alt',
    };

    return (
        <li className="bg-clean-white shadow-sm mt-8 md:mt-0 first:mt-0">
            <article
                itemScope
                itemType="http://schema.org/Article"
                className="h-full"
            >
                <Link
                    to={fields.slug}
                    itemProp="url"
                    className="block relative h-full"
                >
                    {featuredImage?.data && (
                        <GatsbyImage
                            image={featuredImage.data}
                            alt={featuredImage.alt}
                            objectFit="cover"
                            objectPosition="50% 50%"
                            className="aspect-video overflow-hidden w-full"
                            width={400}
                        />
                    )}
                    <div className="px-8">
                        <div>
                            <header className="mb-4">
                                <h2 className="text-md font-bold text-trustful-blue mb-0 mt-6">
                                    <span
                                        itemProp="headline"
                                        className="leading-4"
                                    >
                                        {title}
                                    </span>
                                    <small className="text-sm block font-normal text-blue-40 leading-4">
                                        {date}
                                    </small>
                                </h2>
                            </header>
                            <section
                                itemProp="description"
                                className="text-trustful-blue md:h-48 overflow-hidden"
                            >
                                {excerpt}
                            </section>
                        </div>
                        <div className="h-20">
                            <SecondaryButton
                                className="block mb-4 absolute bottom-0"
                                style={{ width: 'calc(100% - 4rem)' }}
                            >
                                <Trans i18nKey="more">Mehr Lesen</Trans>
                            </SecondaryButton>
                        </div>
                    </div>
                </Link>
            </article>
        </li>
    );
}

function Pagination({ previousPagePath, nextPagePath }) {
    return (
        <Content
            size="big"
            className="px-8 flex justify-between pt-0 -mt-4 gap-4"
        >
            <PrevNextLink path={previousPagePath} isNext={false}>
                <Trans i18nKey="prev">Vorherige Seite</Trans>
            </PrevNextLink>
            <PrevNextLink path={nextPagePath} isNext={true}>
                <Trans i18nKey="next">Nächste Seite</Trans>
            </PrevNextLink>
        </Content>
    );
}

export const query = graphql`
    query($language: String!, $limit: Int, $skip: Int, $categorySlug: String) {
        locales: allLocale(
            filter: { ns: { in: ["blog"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        allMarkdownRemark(
            limit: $limit
            skip: $skip
            filter: {
                frontmatter: {
                    date: { ne: null }
                    categories: { in: [$categorySlug] }
                }
            }
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    id
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(formatString: "MMMM DD, YYYY")
                        categories
                        cover_image {
                            childImageSharp {
                                gatsbyImageData(width: 800)
                            }
                        }
                    }
                }
            }
        }
    }
`;
